/* footer {
    margin-top: 4rem;
    border: 1px dashed #CC0000;
    width: 100%;
    position: absolute;
} */

#footer-container {
    /* background: #6d6c6c none; */
    /* border: 1px dashed #CC0000; */
    /* bottom: 0; */
    /* margin-top: 4rem; */
    width: 100%;
    position: relative;
}

.footer-item {
    /*box-shadow: 7px 7px #7c8eb2;*/
    height: auto;
    max-width: 100%;
    /* border-radius: 12px; */
    background-color: #1f1c1ccc;
    color: white;
    padding-top: 1%;
    padding-bottom: 1%;
    position: relative;
}

.footer-inner-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
}

.footer-heading {
    margin: 1rem;
    text-align: center;
    font-size: 20px;
}

.footer-section {
    padding: 1rem;
    text-align: left;
}

.footer-section > p {
    font-size: large;
}

.footer-section > p > u {
    /* font-size: larger; */
    font-size: 16px;
}

.footer-section #social-icons {
    margin-right: 20px;
}

.footer-section > p > span {
    font-size: 16px;
}

.footer-section > p > strong {
    font-size: 16px;
}

.footer-heading-underline {
    border: 1px solid white;
}

.footer-logo {
    /* height: 250px; */
    width: 375px;
    /* background-color: #cc9900; */
    background-color: #00000077;
    border-radius: 30px;
}

.log-item {
    background-color: #000000;
    color: #FFFFFF;
    font-family: sans-serif;
}

.copyright-container {
    background-color: #000000AA;
}

#copyright {
    text-align: center;
    font-family: sans-serif;
    color: white;
    position: relative;
}

#stats {
    text-align: center;
    position: relative;
}

#last-update-date:after {
    content: '6/22/2020';
}