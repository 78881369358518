.marquee {
    height: 3rem;
    line-height: 3rem;
    width: 100%;
    background-color: #00000000;
    color: yellow;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
}

.marquee p {
    font-size: x-large;
    font-family: KITC, sans-serif;
    display: inline-block;
    padding-left: 100%;
    animation: marquee 20s linear infinite;
}

.shrink-header .marquee {
    height: 1rem;
    line-height: 1rem;
}

.shrink-header .marquee p {
    font-size: small;
}

.disappear-header .marquee {
    display: none;
}

.disappear-header #edit_marque_text_btn {
    display: none;
}

@keyframes marquee {
    0% { transform: translate(0,0); }
    100% { transform: translate(-100%,0); }
}

.marquee p:hover {
    animation: none;
    padding-left: 0%;
    text-align: center;
    width: 100%;
}

@media only screen and (max-width: 960px) {
    
}

@media only screen and (max-width: 800px) {

}

@media only screen and (max-width: 720px) {
    .marquee p {
        /* font-size: larger; */
    }
}

@media screen and (max-width: 620px) {
    
}

@media screen and (max-width: 540px) {
    .marquee p {
        animation-duration: 12s;
    }
}

/*@media only screen and (max-width: 540px) or (max-width: 360px) */
@media screen
and (device-width: 320px)
and (device-height: 640px)
and (-webkit-device-pixel-ratio: 2){
   
}